<template>
  <div class="home">
    <el-container>
      <el-header style="background-color: #4e90ff;">
        <div style="color: #fff; line-height: 60px;">
          <span style="font-size: 20px;">酒店隔离人员管理系统</span>
          <div style="float: right;cursor: pointer;">
            <el-dropdown trigger="click" @command="quitLogin">
              <span class="dctt">
                <img style="width:20px;" src="../assets/img/tx.png" alt />
                <span style="font-size: 14px;color:#fff; margin-left: 8px;">当前用户: {{nickName}}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click="quitLogin">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>

      <el-container style="width:100%;height:100%">
        <el-aside width="220px" style="border-right: 1px solid #dcdcdc;">
          <el-menu
            :unique-opened="true"
            router
            :default-active="$route.path"
            style="border-right: none;"
          >
            <span v-for="(item, index) in navMenu" :key="item.permission">
              <el-submenu v-if="item.children" :index="index+''">
                <template slot="title">{{item.name}}</template>
                <el-menu-item
                  v-for="item2 in item.children"
                  v-if="item.isShow"
                  :key="item2.permission"
                  :index="item2.path"
                >{{item2.name}} {{item.isShow}}</el-menu-item>
              </el-submenu>
              <el-menu-item :index="item.url" v-else>{{item.name}}</el-menu-item>
            </span>
          </el-menu>
        </el-aside>

        <el-main class="home_main" style="height: calc(100vh - 110px);">
          <router-view />
        </el-main>
      </el-container>

      <el-footer class="footer">渝中区疫情防控隔离管理平台 @2021 Copy right</el-footer>
    </el-container>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";

export default {
  name: "home",
  data: function() {
    return {
      nickName: "",
      routerpush: "pcportal", //当前菜单激活
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      navMenu: [] // 导航菜单
    };
  },
  created() {
    this.nickName = this.userdoctor.nickName;

    //根据权限生成动态导航条    // 设置菜单
    this.asyncRouterMap = this.$store.state.permission.addRouters;
    this.navMenu = this.asyncRouterMap[0].children;
    // if (this.roles.code == "HOTEL_ADMIN") {
    //   this.navMenu = adminRules;
    // } else {
    //   this.navMenu = notdAminRules;
    // }
  },

  methods: {
    quitLogin() {
      removeToken();
      this.$router.push("/login");
    }
  }
};
</script>

<style lang='scss' scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
}

.menu {
  width: 220px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px #e4effb;
  padding: 20px;
  box-sizing: border-box;

  .menu_qxd {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;

    .menu_p {
      font-size: 16px;
      letter-spacing: 1px;
      margin-left: 20px;
    }
  }

  .menu_dd {
    width: 180px;
    height: 40px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #3296fa;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu_p {
      font-size: 16px;
      letter-spacing: 1px;
      color: #fff;
    }
  }
}

.active {
  background-color: #3296fa;
  border-radius: 20px;
  align-items: center;
  color: #fff !important;

  .menu_p {
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
  }
}

.footer {
  text-align: center;
  background-color: #e5e5e5;
  line-height: 50px;
  height: 50px !important;
  font-size: 13px;
  color: #6a6a6a;
}

.tcpop {
  width: 20px;
}

.dctt {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
